<template>
<div>
    <v-container fluid class="pa-0 parentDiv">
        <v-img class="bannerProducto" :src="require('@/assets/img/remesa/'+banner+'.png')" />
        <RouterLink to="/">
            <v-img class="backHome" max-width="40" :src="require('@/assets/img/btnHome.png')" />
        </RouterLink>
    </v-container>
    <v-container mt-10>
        <v-img max-width="350" class="mx-auto" src="@/assets/img/logos/ProteccionRemesa.png" />
    </v-container>
    <v-container mt-4 mb-4></v-container>
    <v-container>
        <p class="azul font-19 text-center">
            Protección Remesa cuida de los envíos de dinero que te hacen tus familiares, y te ofrece el servicio
            de repatriación en caso de fallecimiento.
        </p>
        <p class="naranja font-weight-bold font-25 text-center">
            ¡Conoce los beneficios!
        </p>
        <v-container mt-4 mb-4></v-container>
        <v-layout wrap>
            <v-flex xs12 md6>
                <v-layout wrap>
                    <v-flex xs12 md7 class="pa-2 mx-auto">
                        <Bloque :beneficios="beneficiosMiRemesa" img="remesa/MiRemesa.png" titulo="Mi Remesa" descripcion="Protegemos los envíos de dinero que te hagan tus familiares." />
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex xs12 md6>
                <v-layout wrap>
                    <v-flex xs12 md7 class="pa-2 mx-auto">
                        <Bloque :beneficios="beneficiosMiApoyo" img="remesa/MiApoyo.png" titulo="Mi Apoyo" descripcion="En caso de fallecimiento del familiar que hace el envío de la remesa, por cualquier causa." />
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <v-container mt-4 mb-4></v-container>
        <p class="naranja font-weight-bold font-18 text-center">
            Al cobrar tu Remesa en Chedraui, tu dinero y quien te lo manda ¡están protegidos!
        </p>
        <v-container mt-4 mb-4></v-container>
        <v-layout wrap>
            <v-flex xs12 md6 class="text-center pa-4">
                <v-btn color="#0c55a6" width="200" height="35" class="btnNoMayuscula" dark small>Condiciones Generales</v-btn>
            </v-flex>
            <v-flex xs12 md6 class="text-center pa-4">
                <v-btn color="#0c55a6" width="200" :href="'pdf/remesa/KitDigital.pdf'" download="kitDigitalRemesa.pdf" height="35" class="btnNoMayuscula" dark small>Kit Digital</v-btn>
            </v-flex>
        </v-layout>
        <v-container mt-4 mb-4></v-container>
        <p class="text-center line-height">
            <span class="azul font-18">Para solicitar tus servicios o para cualquier duda, contáctanos al:<br>
                Centro de Atención Telefónica</span>
            <br>
            <span class="font-weight-bold naranja font-25"><a class="noList naranja" href="tel:5554816021">55 54 81 60 21</a></span>
            <br>
            <span class="azul font-12">Horario de atención: las 24 horas de los 365 días del año</span>
        </p>
        <p class="azul font-16 text-center">
            La protección de Robo con violencia es otorgada por <br>Protección Remesa operado por Chubb Seguros México, S.A.
        </p>
        <p class="naranja font-13 mt-5 text-center line-height">
            *Aplica solo si cobras tu remesa en Chedraui.
        </p>
    </v-container>

    <Footer programa="Remesa" />

</div>
</template>

<script>
import Bloque from "@/components/BloqueBeneficio.vue";
import Footer from "@/components/FooterPrograma.vue";

export default {
    name: "Despensa",
    components: {
        Bloque,
        Footer
    },
    data() {
        return {
            beneficiosMiRemesa: ["Robo con violencia", "Línea legal"],
            beneficiosMiApoyo: ["Repatriación"]
        }
    },
    computed: {
        banner() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 'BannerRemesaMovil';
                case 'sm':
                    return 'BannerRemesaMovil';
                case 'md':
                    return 'BannerRemesa';
                case 'lg':
                    return 'BannerRemesa';
                case 'xl':
                    return 'BannerRemesa';
            }
        }
    }
}
</script>
